import { useRef, useState, useEffect, useCallback } from 'react';

// ***** Source: https://usehooks.com/useHover/

export const useHover = <T extends HTMLElement>() => {
  const [isHovering, setIsHovering] = useState(false);

  const ref = useRef<T>(null);

  const handleMouseOver = useCallback(() => setIsHovering(true), [setIsHovering]);
  const handleMouseOut = useCallback(() => setIsHovering(false), [setIsHovering]);

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseleave', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseleave', handleMouseOut);
      };
    }
  }, [ref.current, handleMouseOver, handleMouseOut]);

  return { ref, isHovering };
};
