import { useCallback, useState } from 'react';

interface IAsyncActionsOptions<T> {
  initialData?: any;
  onSuccess?(data: T): any;
  onFailure?(error: any): any;
  onFinish?(): any;
  initialIsPending?: boolean;
  shouldThrowError?: boolean;
  retries?: number;
  retryDelayMs?: number;
}

const apiCallWithRetries = async (
  callback: () => Promise<any>,
  retries: number,
  { retryDelayMs }: { retryDelayMs: number },
): Promise<{ result: any }> => {
  const attempts = Array.from(Array(retries + 1)).map((_, index) => index + 1);

  for await (const attemptNumber of attempts) {
    try {
      const result = await callback();

      return { result };
    } catch (e) {
      if (attemptNumber === retries + 1) {
        throw e;
      } else {
        await new Promise(resolve => setTimeout(resolve, retryDelayMs));
      }
    }
  }

  return { result: undefined };
};

/**
 * @deprecated use react query useMutation instead;
 * `const { mutate } = useMutation({ mutateFn: doSomething })`
 */
export const useAsyncAction = <T, P = any>(
  apiCall: (args?: P) => Promise<T>,
  {
    initialData,
    onSuccess,
    onFailure,
    onFinish,
    initialIsPending,
    shouldThrowError = false,
    retries = 0,
    retryDelayMs = 1000,
  }: IAsyncActionsOptions<T> = {},
) => {
  const [isPending, setIsPending] = useState<boolean>(initialIsPending ?? false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<T>(initialData);

  const action = useCallback(
    async (params?: P) => {
      try {
        setIsPending(true);
        setError(null);

        const { result: responseData } = await apiCallWithRetries(async () => apiCall(params), retries, {
          retryDelayMs,
        });

        setData(responseData);

        await onSuccess?.(responseData);
      } catch (apiError) {
        setError(apiError);

        await onFailure?.(apiError);

        if (shouldThrowError) {
          throw apiError;
        }
      } finally {
        setIsPending(false);

        await onFinish?.();
      }
    },
    [apiCall],
  );

  return { isPending, error, action, data };
};
