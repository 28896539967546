import { useState, useEffect } from 'react';

// ***** Source: https://usehooks.com/useScript/

const cachedScripts: Record<
  string,
  null | {
    currentState: {
      isError: boolean;
      isLoading: boolean;
    };
    onErrorSubscriber: (() => void)[];
    onLoadSubscriber: (() => void)[];
  }
> = {};

export const useScript = (src: string) => {
  const [{ isError, isLoading }, setState] = useState<{ isError: boolean; isLoading: boolean }>({
    isError: false,
    isLoading: true,
  });

  useEffect(() => {
    const scriptState = cachedScripts[src];

    if (scriptState) {
      const { isError, isLoading } = scriptState.currentState;

      setState({ isError, isLoading });

      if (!isLoading) return;

      scriptState.onErrorSubscriber.push(() => setState({ isError: true, isLoading: false }));
      scriptState.onLoadSubscriber.push(() => setState({ isError: false, isLoading: false }));
    } else {
      cachedScripts[src] = {
        currentState: { isError, isLoading },
        onErrorSubscriber: [],
        onLoadSubscriber: [],
      };

      const onScriptLoad = () => {
        const scriptState = cachedScripts[src];

        if (!scriptState) return;

        scriptState.currentState = { isError: false, isLoading: false };
        setState({ isError: false, isLoading: false });
        scriptState.onLoadSubscriber.forEach(fn => fn());
      };

      const onScriptError: OnErrorEventHandler = event => {
        script.remove();

        const scriptState = cachedScripts[src];

        if (!scriptState) return;

        scriptState.onErrorSubscriber.forEach(fn => fn());
        cachedScripts[src] = null;
        setState({ isError: true, isLoading: false });
      };

      const script = document.createElement('script');

      script.src = src;
      script.async = true;
      script.defer = true;
      script.onload = onScriptLoad;
      script.onerror = onScriptError;

      document.body.appendChild(script);

      return function cleanup() {
        script.onload = null;
        script.onerror = null;
      };
    }
  }, [src]);

  return { isScriptLoading: isLoading, isScriptError: isError };
};
