import { useEffect, useRef, EffectCallback, DependencyList } from 'react';

export const useUpdateEffect = (effect: EffectCallback, deps: DependencyList) => {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      effect();
    }
  }, deps);
};
