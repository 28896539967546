import { useState, useEffect, createContext, useContext, PropsWithChildren } from 'react';
import { debounce } from 'lodash';

const defaultValue = {};
const BreakpointContext = createContext(defaultValue);
const debounceDuration = 500;
const queries = {
  sm: '(max-width: 1399px)',
  md: '(max-width: 1920px)',
  lg: '(min-width: 1921px)',
} as const;

type BreakPoint = Partial<Record<keyof typeof queries, boolean>>;

const BreakpointProvider = ({ children }: PropsWithChildren<{}>) => {
  const [queryMatch, setQueryMatch] = useState({});

  useEffect(() => {
    const mediaQueryLists: Record<string, MediaQueryList> = {};
    const mediaQueryNames = Object.keys(queries) as (keyof typeof queries)[];
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = mediaQueryNames.reduce<Record<string, boolean>>((acc, media) => {
        acc[media] = Boolean(mediaQueryLists[media]?.matches);

        return acc;
      }, {});

      setQueryMatch(updatedMatches);
    };
    const debouncedListener = debounce(handleQueryListener, debounceDuration);

    if (window?.matchMedia) {
      const matches: Record<string, boolean> = {};

      mediaQueryNames.forEach(media => {
        mediaQueryLists[media] = window.matchMedia(queries[media]);
        matches[media] = mediaQueryLists[media].matches;
      });
      setQueryMatch(matches);
      mediaQueryNames.forEach(media => {
        mediaQueryLists[media].addListener(debouncedListener);
      });
      isAttached = true;
    }

    return () => {
      if (isAttached) {
        mediaQueryNames.forEach(media => {
          mediaQueryLists[media].removeListener(debouncedListener);
        });
      }
    };
  }, [queries]);

  return <BreakpointContext.Provider value={queryMatch}>{children}</BreakpointContext.Provider>;
};

function useBreakpoint() {
  const context = useContext<BreakPoint>(BreakpointContext);

  return context;
}

export { useBreakpoint, BreakpointProvider };
