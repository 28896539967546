import { useState } from 'react';

let i = 0;
export const useId = () => {
  const [id] = useState<string>(() => {
    i += 10;

    return `uid-${i}`;
  });

  return id;
};
