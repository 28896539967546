import React from 'react';
import { isEqual } from 'lodash';
import { FormikProps } from 'formik';

// This hook invoke formik's submit function upon any value change
// found here https://github.com/formium/formik/issues/2769#issuecomment-698494400
export const SubmitFormikOnUpdate = React.memo(({ formik }: { formik: FormikProps<any> }) => {
  const [lastValues, updateState] = React.useState(formik.values);

  React.useEffect(() => {
    if (!isEqual(lastValues, formik.values)) {
      updateState(formik.values);
      formik.submitForm();
    }
  }, [formik.values, formik.isValid]);

  return null;
});
